import React from "react";
import { Link } from "gatsby";
import banner from "../images/projects/spot/spot-club-banner.jpg";

import Layout from "../components/layout";

const Blog = () => {
    const BlogCard = () => (
        <Link to={"/blogPage"}>
            <div className="blog-post">
                <div className="blog-post-img">
                    <img src={banner} alt="#" />
                </div>
                <div className="blog-post-text">
                    <div className="date">27 июня 2019</div>
                    <div className="title">
                        7 правил создания красивых интерфейсов
                    </div>
                </div>
            </div>
        </Link>
    );

    return (<Layout>
            <div className="blog-wrapper">
                <div className="blog-header">
                    <div className="blog-header-img">
                        <img src={banner} alt="#" />
                    </div>
                    <div className="blog-header-title intro-title">
                        7 правил создания {"\n"}красивых интерфейсов
                    </div>
                </div>
                <div className="container blog">
                    <BlogCard />
                    <BlogCard />
                    <BlogCard />
                    <BlogCard />
                    <BlogCard />
                    <BlogCard />
                    <BlogCard />
                    <BlogCard />
                    <BlogCard />
                </div>
            </div>
        </Layout>);
};

export default Blog;
